@-webkit-keyframes animate-svg-stroke-1 {
    0% {
      stroke-dashoffset: 846px;
      stroke-dasharray: 846px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 846px;
    }
  }
  
  @keyframes animate-svg-stroke-1 {
    0% {
      stroke-dashoffset: 846px;
      stroke-dasharray: 846px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 846px;
    }
  }
  
  @-webkit-keyframes animate-svg-fill-1 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(0, 0, 0);
    }
  }
  
  @keyframes animate-svg-fill-1 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(0, 0, 0);
    }
  }
  
  .svg-elem-1 {
    -webkit-animation:
      animate-svg-stroke-1 3s cubic-bezier(0.47, 0, 0.745, 0.715) 0s both,
      animate-svg-fill-1 2.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) 0.8s both;
    animation:
      animate-svg-stroke-1 3s cubic-bezier(0.47, 0, 0.745, 0.715) 0s both,
      animate-svg-fill-1 2.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) 0.8s both;
  }
  
  @-webkit-keyframes animate-svg-stroke-2 {
    0% {
      stroke-dashoffset: 869.2617797851562px;
      stroke-dasharray: 869.2617797851562px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 869.2617797851562px;
    }
  }
  
  @keyframes animate-svg-stroke-2 {
    0% {
      stroke-dashoffset: 869.2617797851562px;
      stroke-dasharray: 869.2617797851562px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 869.2617797851562px;
    }
  }
  
  @-webkit-keyframes animate-svg-fill-2 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(0, 0, 0);
    }
  }
  
  @keyframes animate-svg-fill-2 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(0, 0, 0);
    }
  }
  
  .svg-elem-2 {
    -webkit-animation:
      animate-svg-stroke-2 3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s both,
      animate-svg-fill-2 2.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) 0.9s both;
    animation:
      animate-svg-stroke-2 3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s both,
      animate-svg-fill-2 2.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) 0.9s both;
  }
  
  @-webkit-keyframes animate-svg-stroke-3 {
    0% {
      stroke-dashoffset: 869.2617797851562px;
      stroke-dasharray: 869.2617797851562px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 869.2617797851562px;
    }
  }
  
  @keyframes animate-svg-stroke-3 {
    0% {
      stroke-dashoffset: 869.2617797851562px;
      stroke-dasharray: 869.2617797851562px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 869.2617797851562px;
    }
  }
  
  @-webkit-keyframes animate-svg-fill-3 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(0, 0, 0);
    }
  }
  
  @keyframes animate-svg-fill-3 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(0, 0, 0);
    }
  }
  
  .svg-elem-3 {
    -webkit-animation:
      animate-svg-stroke-3 3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s both,
      animate-svg-fill-3 2.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) 1s both;
    animation:
      animate-svg-stroke-3 3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s both,
      animate-svg-fill-3 2.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) 1s both;
  }
  
  @-webkit-keyframes animate-svg-stroke-4 {
    0% {
      stroke-dashoffset: 1239.359375px;
      stroke-dasharray: 1239.359375px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 1239.359375px;
    }
  }
  
  @keyframes animate-svg-stroke-4 {
    0% {
      stroke-dashoffset: 1239.359375px;
      stroke-dasharray: 1239.359375px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 1239.359375px;
    }
  }
  
  @-webkit-keyframes animate-svg-fill-4 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(0, 0, 0);
    }
  }
  
  @keyframes animate-svg-fill-4 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(0, 0, 0);
    }
  }
  
  .svg-elem-4 {
    -webkit-animation:
      animate-svg-stroke-4 3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s both,
      animate-svg-fill-4 2.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) 1.1s both;
    animation:
      animate-svg-stroke-4 3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s both,
      animate-svg-fill-4 2.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) 1.1s both;
  }
  
  @-webkit-keyframes animate-svg-stroke-5 {
    0% {
      stroke-dashoffset: 646.7607421875px;
      stroke-dasharray: 646.7607421875px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 646.7607421875px;
    }
  }
  
  @keyframes animate-svg-stroke-5 {
    0% {
      stroke-dashoffset: 646.7607421875px;
      stroke-dasharray: 646.7607421875px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 646.7607421875px;
    }
  }
  
  @-webkit-keyframes animate-svg-fill-5 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(210, 150, 74);
    }
  }
  
  @keyframes animate-svg-fill-5 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(210, 150, 74);
    }
  }
  
  .svg-elem-5 {
    -webkit-animation:
      animate-svg-stroke-5 3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s both,
      animate-svg-fill-5 2.5s cubic-bezier(0.55, 0.085, 0.68, 0.53)
        1.2000000000000002s both;
    animation:
      animate-svg-stroke-5 3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s both,
      animate-svg-fill-5 2.5s cubic-bezier(0.55, 0.085, 0.68, 0.53)
        1.2000000000000002s both;
  }
  
  @-webkit-keyframes animate-svg-stroke-6 {
    0% {
      stroke-dashoffset: 94011.5703125px;
      stroke-dasharray: 94011.5703125px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 94011.5703125px;
    }
  }
  
  @keyframes animate-svg-stroke-6 {
    0% {
      stroke-dashoffset: 94011.5703125px;
      stroke-dasharray: 94011.5703125px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 94011.5703125px;
    }
  }
  
  .svg-elem-6 {
    -webkit-animation:
      animate-svg-stroke-6 3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s both,
      animate-svg-fill-6 2.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) 1.3s both;
    animation:
      animate-svg-stroke-6 3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s both,
      animate-svg-fill-6 2.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) 1.3s both;
  }
  
  @-webkit-keyframes animate-svg-stroke-7 {
    0% {
      stroke-dashoffset: 295.43182373046875px;
      stroke-dasharray: 295.43182373046875px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 295.43182373046875px;
    }
  }
  
  @keyframes animate-svg-stroke-7 {
    0% {
      stroke-dashoffset: 295.43182373046875px;
      stroke-dasharray: 295.43182373046875px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 295.43182373046875px;
    }
  }
  
  @-webkit-keyframes animate-svg-fill-7 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(115, 128, 98);
    }
  }
  
  @keyframes animate-svg-fill-7 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(115, 128, 98);
    }
  }
  
  .svg-elem-7 {
    -webkit-animation:
      animate-svg-stroke-7 3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.72s both,
      animate-svg-fill-7 2.5s cubic-bezier(0.55, 0.085, 0.68, 0.53)
        1.4000000000000001s both;
    animation:
      animate-svg-stroke-7 3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.72s both,
      animate-svg-fill-7 2.5s cubic-bezier(0.55, 0.085, 0.68, 0.53)
        1.4000000000000001s both;
  }