@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: gotham-black;
    src: url('./assets/fonts/Gotham-Black.otf');
  }
  @font-face {
    font-family: gotham-bold;
    src: url('./assets/fonts/Gotham-Bold.otf');
  }
  @font-face {
    font-family: gotham-book;
    src: url('./assets/fonts/Gotham-Book.otf');
  }
  @font-face {
    font-family: gotham-light;
    src: url('./assets/fonts/Gotham-Light.otf');
  }
  @font-face {
    font-family: gotham-medium;
    src: url('./assets/fonts/Gotham-Medium.otf');
  }
  @font-face {
    font-family: la-belle;
    src: url('./assets/fonts/LaBelleAurore.woff2');
  }
  @font-face {
    font-family: dream-ave;
    src: url('./assets/fonts/Dream-Avenue.ttf');
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #c8c8c8 !important;
}

input:-moz-autofill,
input:-moz-autofill:hover,
input:-moz-autofill:focus {
    background: transparent !important;
}

input:focus ~ label,
input:not(:placeholder-shown) ~ label,
textarea:focus ~ label,
textarea:not(:placeholder-shown) ~ label {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-scale-x: 0.75;
  --tw-scale-y: 0.75;
  --tw-translate-y: -1.1rem;
}